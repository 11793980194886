import React, {useState} from 'react';
import './App.css';
import OrderConfigurator from "./components/OrderConfigurator/OrderConfigurator";
import OrderSchedule from "./components/OrderSchedule/OrderSchedule";

function App() {
    const [order, setOrder] = useState([]);
    const [lunghezzaStecca, setLunghezzaStecca] = useState(6500);

    function addToOrder(row) {
        // console.log(row);
        setOrder( [...order, row]);
    }

    function addMultipleRowsToOrder(rows) {
        console.log(rows);
        var rowsArray = []
        for(var i=0; i<rows.length; i++) {
            if(i>0 && rows[i][0] && rows[i][2] && rows[i][3]) {
                var row = {
                    'numTapparelle': rows[i][0],
                    'color': rows[i][1],
                    'numStecche': rows[i][2],
                    'misuraTaglio': rows[i][3]
                }
                rowsArray.push(row);
            }
        }
        setOrder( rowsArray);
    }

    function deleteRow(index) {
        const orderCopy = [...order];
        orderCopy.splice(index, 1);
        setOrder(orderCopy);
    }

      return (
        <div className="content">
          <OrderConfigurator
              lunghezzaStecca={lunghezzaStecca}
              onChangeLunghezzaStecca={(e) => setLunghezzaStecca(e.target.value)}
              onExcelImport={addMultipleRowsToOrder}
              onButtonClick={addToOrder}
          />
          <OrderSchedule
            order={order}
            lunghezzaStecca={lunghezzaStecca}
            onReset={() => setOrder([])}
            onRowDelete={(i) => deleteRow(i)}
          />
        </div>
      );
}

export default App;
