import React, {useState, useRef} from 'react';
import './OrderConfigurator.scss';
import {ExcelRenderer} from "react-excel-renderer";

const initialState = {
    numTapparelle: '',
    numStecche: '',
    misuraTaglio: '',
    color: '',
}

export default function OrderConfigurator(props) {
    const numTapInput = useRef(null)
    const [row, setRow] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    function fileHandler(event) {
        let fileObj = event.target.files[0];
        setIsLoading(true);
        setError('');

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if(err){
                console.log(err);
                setError('Qualcosa è andato storto. Riprova')
            }
            else{
                props.onExcelImport(resp.rows)
                event.target.value = null;
                setIsLoading(false);
            }
        });

    }

    return (
        <div className="order-configurator">
            <div className="title">
                <span>Configuratore Ordine</span>
                <input
                    name="lunghezzaStecca"
                    className="input"
                    value={props.lunghezzaStecca}
                    onChange={props.onChangeLunghezzaStecca}
                    type="number"
                    placeholder="Lunghezza Stecca(mm)"
                />
            </div>
            <div className="subtitle">
                Inserire i parametri relativi all'ordine e cliccare sul pulsante invio per aggiungerli alla Scheda Ordine
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.onButtonClick(row);
                setRow(initialState);
                numTapInput.current.focus();
            }}>
                <div className="form-wrapper">
                    {/*<div className="header">*/}
                    {/*    <p>Num.Tapparelle</p>*/}
                    {/*    <p>Num.Stecche</p>*/}
                    {/*    <p>Misura Taglio</p>*/}
                    {/*</div>*/}
                    <div className="input-row">
                        <input
                            ref={numTapInput}
                            name="numTapparelle"
                            className="input"
                            value={row.numTapparelle}
                            onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                            type="number"
                            placeholder="Numero Tapparelle"
                        />
                        <input
                            name="numStecche"
                            className="input"
                            value={row.numStecche}
                            onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                            type="number"
                            placeholder="Numero Stecche"
                        />
                        <input
                            name="misuraTaglio"
                            className="input"
                            value={row.misuraTaglio}
                            onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                            type="number"
                            placeholder="Misura Taglio (mm)"
                        />
                        <input
                            name="color"
                            className="input"
                            value={row.color}
                            onChange={(e) => setRow({...row, [e.target.name]: e.target.value})}
                            type="text"
                            placeholder="Colore"
                        />
                    </div>
                </div>
                <div className="button-wrapper">
                    <button
                        className="configurator-button"
                        type="submit"
                        disabled={!row.numTapparelle || !row.numStecche || !row.misuraTaglio}
                    >
                        Aggiungi
                    </button>
                    <div className="import-button">
                        <label className="configurator-button" htmlFor="file-input">{isLoading ? 'Loading...' : 'Importa da Excel'}</label>
                        <input id="file-input" type="file" accept=".xls,.xlsx" name="file" onChange={(e) => fileHandler(e)} style={{"display":"none"}} />
                    </div>
                </div>
                {error &&
                    <div className="error">{error}</div>
                }
            </form>
        </div>
    );
}

