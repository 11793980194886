import React, {useState, useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import './OrderSchedule.scss';

export default function OrderSchedule(props) {
    const [steccheNecessarie, setSteccheNecessarie] = useState(0);
    const [spreco, setSpreco] = useState(0);
    const [tagliRaggruppati, setTagliRaggruppati] = useState([]);
    const [numOrdine, setNumOrdine] = useState('');
    const [customer, setCustomer] = useState('');

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function sumSteccheRimanenti(array) {
        var steccheRimanenti = 0;
        for(var i=0; i<array.length; i++) {
            steccheRimanenti += array[i].steccheRimanenti;
        }
        return steccheRimanenti;
    }

    function ottimizza() {
        const lunghezzaStecca = props.lunghezzaStecca ? props.lunghezzaStecca : 6500; //La lunghezza della steccha da tagliare
        const taglioLama = 5; //Spreco che si ha durante ogni taglio
        var counterStecche = 0; //Contatore di stecche necessarie per l'ordine
        var spreco = 0; //Spreco
        var indicazioniTagli = []; //Indica come tagliare ogni sbarra
        var tagliRaggruppati = []; //Raggruppo tutti i tagli uguali tra loro

        var order = [...props.order];

        //Calcolo il numero totale di stecche moltiplicando numero tapparelle per numero stecche
        order.map(row => {
            row.steccheRimanenti = row.numStecche * row.numTapparelle
        })

        //Ordino le righe in ordine decrescente per la misura taglio
        var sortedOrder = order.sort((a, b) => {
            if(Number(a.misuraTaglio) < Number(b.misuraTaglio))
                return 1;
            return -1;
        })

        //Continuo finchè mi restano 0 stecche da produrre
        var steccheTotaliDaProdurre = sumSteccheRimanenti(order);
        console.log('TOT STECCHE RIMANENTI');
        console.log(steccheTotaliDaProdurre);

        console.log(sortedOrder);
        while(steccheTotaliDaProdurre > 0) {
            var lunghezzaSteccaRimanente = lunghezzaStecca;
            var tagli = [];
            var tagliStringa = '';
            sortedOrder.map(row => {
                while (Number(lunghezzaSteccaRimanente) > Number(row.misuraTaglio) && row.steccheRimanenti > 0) {
                    lunghezzaSteccaRimanente = lunghezzaSteccaRimanente - row.misuraTaglio - taglioLama;
                    row.steccheRimanenti--;
                    steccheTotaliDaProdurre--;
                    tagli.push(row.misuraTaglio);
                    tagliStringa += row.misuraTaglio + ' - ';
                }
            })

            indicazioniTagli.push(tagliStringa.substring(0, tagliStringa.length - 3));
            counterStecche++;
            spreco += lunghezzaSteccaRimanente;
        }

        var occurrences = { };
        for (var i = 0, j = indicazioniTagli.length; i < j; i++) {
            occurrences[indicazioniTagli[i]] = (occurrences[indicazioniTagli[i]] || 0) + 1;
        }

        //Raggruppo i duplicati
        for (const [key, value] of Object.entries(occurrences)) {
            const obj = {'value': key, 'number': value};
            tagliRaggruppati.push(obj);
        }

        setTagliRaggruppati(tagliRaggruppati);
        setSteccheNecessarie(counterStecche);
        setSpreco(spreco);
    }

    return (
        <div className="order-schedule">
            <div className="title">
                Scheda Ordine
            </div>
            <div className="customer-input">
                <input
                    name="numOrdine"
                    className="input"
                    value={numOrdine}
                    onChange={(e) => {setNumOrdine(e.target.value)}}
                    type="text"
                    placeholder="Numero Ordine"
                />
                <input
                    name="customer"
                    className="input"
                    value={customer}
                    onChange={(e) => {setCustomer(e.target.value)}}
                    type="text"
                    placeholder="Nome Cliente"
                />
            </div>
            <div className="order-schedule-content">
                <div className="header">
                    <p>Num. Tapparelle</p>
                    <p>Num. Stecche</p>
                    <p>Misura Taglio</p>
                    <p>Colore</p>
                    <p className="delete-icon"></p>
                </div>
                <div className="items">
                    {props.order.map((orderRow, i) =>
                        <div className="items-row" key={i}>
                            <p>{orderRow.numTapparelle}</p>
                            <p>{orderRow.numStecche}</p>
                            <p>{orderRow.misuraTaglio} mm</p>
                            <p>{orderRow.color}</p>
                            <p className="delete-icon">
                                <span onClick={() => props.onRowDelete(i)}>X</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="order-schedule-button">
                <button
                    className="configurator-button"
                    onClick={ottimizza}
                    disabled={!props.order.length}
                >
                    Ottimizza
                </button>
                <button
                    className="reset-button"
                    onClick={() => {
                        setSpreco(0);
                        setSteccheNecessarie(0);
                        setTagliRaggruppati([]);
                        props.onReset()
                    }}
                >
                    Reset
                </button>
                <button
                    className="print-button"
                    onClick={handlePrint}
                >
                    Stampa
                </button>
            </div>
            <div id="print-wrapper" className="order-schedule-result" ref={componentRef}>
                <p><b>ID Ordine:</b> {numOrdine} / <b>Cliente:</b> {customer}</p>
                <p><b>Barre Necessarie:</b> {steccheNecessarie} / <b>Spreco:</b> {spreco/1000} m</p>
                <table cellspacing="0" rules="all" border="1">
                    <tr>
                        <th>Num. Tapparelle</th>
                        <th>Num. Stecche</th>
                        <th>Misura Taglio</th>
                        <th>Colore</th>
                    </tr>
                    {props.order.map((orderRow, i) =>
                        <tr key={i}>
                            <td>{orderRow.numTapparelle}</td>
                            <td>{orderRow.numStecche}</td>
                            <td>{orderRow.misuraTaglio} mm</td>
                            <td>{orderRow.color}</td>
                        </tr>
                    )}
                </table>
                <div className="order-schedule-cut">
                    <p style={{marginTop: '40px'}}>Tagliare le barre come segue: </p>
                    {tagliRaggruppati.map((tagli, i) =>
                        <p><strong>{tagli.number} Barre:</strong> <span>{tagli.value}</span></p>
                    )}
                </div>
            </div>
        </div>
    );
}

